<template>
<div class="main">
  <div class="component-content">
    <Loading v-if="loading"/>

    <TopMenu class="mb-12p">

      <template v-slot:page-name>仓库</template>

      <template v-slot:menu-block1>
        <button class="btn btn-blue" :disabled="allowAttack" @click="resetStore()">
          <span class="mr-2">重置库存</span>
          <img class="icon-16 d-inline-block" src="/static/images/icon/save.svg">
        </button>
      </template>

    </TopMenu>

    <SubMenu
      :labels="check"
      :options="submenu"
      @call="_call"
    >
      <template v-slot:left-menu>

        <div class="li ml-auto">
          <div class="input-group flex-1 mr-2p h-100">
            <span class="input-group-text">
              <img class="icon-16" src="/static/images/icon/search.svg">
            </span>
            <input type="text" class="form-control font-12" placeholder="搜索产品" v-model="submenu.searchProduct.value" @change="_search">
            <button class="btn btn-close" v-show="submenu.searchProduct.value" @click="_clearSearchProduct()">
              <img class="icon-12" src="/static/images/icon/close.svg">
            </button>
          </div>
        </div>

      </template>
    </SubMenu>

    <div class="content bg-25 h-100">
      <div class="d-flex">
        <label><div class="flex-list-header hr"><input name="check" type="checkbox" :checked="checkAll" @click="funCheckAll" /></div></label>
        <div class="flex-list hr w-100">
          <div style="width:20%;padding-left:24px;">名称</div>
          <div style="width:8%;">规格</div>
          <div style="width:8%;">单位</div>
          <div style="width:8%;">包装</div>
          <div style="width:8%;">形态</div>
          <div style="width:8%;">型号</div>
          <div style="width:13%;">库存数量</div>
          <div style="width:12%;">平均价格</div>
          <div style="width:15%;">总价格</div>
        </div>
      </div>

      <div
      :class="active==index?'d-flex hover active':'d-flex hover'"
      v-for="(data, index) in datas.product"
      :key="data.id"
      >
          <label><div class="flex-list-header hr"><input name="check" type="checkbox" :value="data" v-model="check"/></div></label>
          <div class="flex-list hr w-100 btn-default" @click="showList(data, index)">
            <div style="width:20%;padding-left:12px;">{{ data.name }}</div>
            <div style="width:8%;">{{ data.specification }}</div>
            <div style="width:8%;">{{ data.unit }}</div>
            <div style="width:8%;">{{ data.package }}</div>
            <div style="width:8%;">{{ data.status }}</div>
            <div style="width:8%;">{{ data.model }}</div>
            <div style="width:13%;">{{ data.quantity }}</div>
            <div style="width:12%;">{{ $math.Div(data.total_price, data.quantity) }}</div>
            <div style="width:15%;">{{ $math.formatMoney(data.total_price, 2) }}</div>
          </div>
      </div>
    </div>

    <div class="pagination">
      <v-pagination
        v-model="currentPage"
        :pages="totalPage"
        active-color="#004bff"
        @update:modelValue="getDatas($event, submenu.searchProduct.value)"
      />
    </div>
  </div>
</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import SubMenu from '@/components/SubMenu.vue'

export default {
  name: 'StoreList',

  data() {
    return {
      loading: true,
      datas: [],
      active: null,
      check: [],
      checkAll: false,
      currentPage: 1,
      totalPage: 1,
      submenu: {
        export: {
          fetch: '/api/product/export',
          permissions: '205'
        },
        searchProduct: {
          value: '',
        },
      }
    }
  },

  mounted() {
    console.log(this.$options.name+' 挂载')

    this.getDatas()
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
    SubMenu
  },

  watch: {
    check: function() {
      if (this.datas.product && this.datas.product.length > 0) {
        //全选
        if (this.check.length != this.datas.product.length) {
          this.checkAll = false
        } else {
          this.checkAll = true
        }
      }
    },

    datas: function() {
      this.checkAll = false
      this.check = []
      this.active = null
    },
  },

  methods: {
    getDatas(page, search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let p = page ? page : 1
        _this.$axios.get('/api/product', {
          headers: {
            token: JSON.parse(sessionStorage.Authorization).token
          },
          params: {
            page: p,
            take: 20,
            search: search
          }
        })
        .then(res => {
          resolve(res);

          if (res.status == 200) {
            if (res.data) {
              this.datas = res.data
              this.currentPage = res.data.current_page
              this.totalPage = res.data.total_page
            }
            this.loading = false
          }
          console.log(res)
        })
        .catch(error => {
          reject(error)
          console.log('error:', error)
        })
      });
    },

    showList(data, index) {
      this.active = index
    },

    funCheckAll() {
      let _this = this
      this.check = []
      if (!this.checkAll && this.datas.product) {
        this.datas.product.forEach(function(item) {
          _this.check.push(item)
        })
      }
      this.checkAll = !this.checkAll
    },

    resetStore() {
      let _this = this
      this.$vfm.show({
        component: 'ShowMessage',
        on: {
          confirm(close) {
            close()

            _this.$axios.post('/api/product/reset-store',
              {
                data: _this.datas
              },
              {
                headers: {
                  token: JSON.parse(sessionStorage.Authorization).token
                }
              }
            )
            .then(res => {
              if (res.status == 200) {
                _this.datas = res.data.data
                _this.currentPage = res.data.data.current_page
                _this.totalPage = res.data.data.total_page
                _this.$toast.success(res.data.message)
                console.log(res.data)
              }
            })
            .catch(error => {
              console.error('error:', error)
            })
          },
          cancel(close) {
            console.log('取消')
            close()
          },
          closed() {
            console.log('@closed')
          }
        },
        slots: {
          title: '提示',
          default: '确定要重置所有库存数据吗？'
        }
      })
    },

    //子组件方法
    _search() {
      let search = this.submenu.searchProduct.value
      this.getDatas(null, search)
    },

    _clearSearchProduct() {
      this.submenu.searchProduct.value = '';
      this._search()
    },

    _call(data) {
      if (data.res.success) {
        let _this = this
        //let oldDatas = this.datas.order

        if (data.function == 'del') {
          this.getDatas(this.currentPage).then(res => {
            if (res.status == 200) {
              _this.datas = res.data
              if (res.data.current_page > res.data.total_page) {
                this.getDatas(res.data.total_page)
              }
            }
          })
        }

        if (data.function == 'copy') {
          this.getDatas().then(res => {
            if (res.status == 200) {
              res.data.product.forEach(function(item) {
                let newData = false;
                data.res.data.forEach(function(i) {
                  if (item.id == i) {
                    newData = true;
                    return false;
                  }
                })
                item.new_data = newData
              })
              _this.datas = res.data
            }
          })
        }
      }
    }

  },
  
}
</script>

<style scoped>
.active {
  background-color: #004bff !important;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col-6 {
  position: relative;
  width: 100%;
  padding-right: 1px;
  padding-left: 1px;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-6:first-child {
  padding-left: 0;
}
.col-6:last-child {
  padding-right: 0;
}

.btn-close {
  padding: 6px;
  border-radius: 100%;
}

</style>
